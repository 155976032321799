

  .search{
    width: 100%;
    max-width: 550px;

    .search-bar {
      color: var(--placeholder-color);
      z-index: 10;
      display: flex;
      background-color: #f2f2f2;
      align-items: center;
      border-radius: 10px;
      overflow: hidden;
      flex: 1 1;
      width: 100%;
      height: 45px;
    
    }

    .search-bar-container{
      // border: 1px solid #BDBDBD;
      border-radius: 8px;
    }
    
    .search-input {
      border: none;
      font-family: inherit;
      background: none;
      flex: 1;
      outline: none;
      font-size: 15px;
      padding: 12px 10px;
      border-radius: 0 0 10px 10px;
      height: 45px;
    


    }
    
    .search-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0px 10px 20px;
      font-size: 21px;
      border: none;
      background: none;
      cursor: pointer;
      height: 100%;
    }
    
    .search-data{
      position:absolute;
      z-index: 999;
      background-color: var(--white);
      top: 70px;
      min-width:550px;
      overflow: hidden;
      overflow-y: auto;
      max-height: 255px;
      // border-radius: 10px;
      border: 1px solid #999;
      box-shadow: 0px 15px 19px #3E3E3E1F;
  
      &::-webkit-scrollbar{
        display: none;
      }
  
      .result{
        padding: 0.8rem;
        width: 100%;
        display: flex;
        height: 100%;
        cursor: pointer;
        
      }
      .result:hover{
        background-color: var(--background-color);
      }
    }
  
    @media (max-width:786px) {
      width: 100%;
      max-width: 100%;
      margin-top: 1rem;
      order: 3;
      flex-grow: 1;
      flex-basis: 100%;

      .search-bar-overlay{
      width: 100%;
      height: 100vh;
      position: fixed;
      background-color: white;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9;
      display: flex;
      padding: 0 1rem;
      justify-content: center;

      .search-bar{
        margin-top: 64px;
      }

    
      }

      .search-data{
        position: absolute;
        z-index: 1;
        background-color: var(--white);
        top: 135px;
        min-width: 100%;
        overflow: hidden;
        max-height: 100%;
        border: none;
        padding: 0 1rem;
        box-shadow: none;
        
      }

  
  }



}


@media (max-width:786px) {
  .search-md-hidden{
    display: none;
  }
}