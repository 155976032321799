
.category{
    width: fit-content !important;
    margin-right: 1.6rem;
    font-size: 14px;
}

.category_swiper{

    .swiper-button-next, .swiper-button-prev{
        width: 50px;
        background-color: white; 
        background-blend-mode: overlay;
        backdrop-filter: drop-shadow(2px 4px 10px white);

    }

}