

.category_circle{
    width: 100%;
    display: flex;
    text-align: center;
    font-size: 15px;
    color: var(--dark);
    justify-content: center;
    flex-direction: column;
    align-items: center; 
}

.category_image{
    width: 135px;
    height: 135px;
    border-radius: 100%;

    img{
        border-radius: 100%;
    }

}

.category_text{
    margin-top: 1rem;
    margin-bottom: 1rem;
}



@media (max-width:450px) {

    .category_circle{
    font-weight:500;
     margin: 0;
 } 

 .category_image{
    max-width: calc(33.333vw - 2rem);
    max-height: calc(33.333vw - 2rem);;

}


}