.nav-list, .header_buttons{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 2;
  }

  .nav-list{
  
    &>:last-child{
      margin: 0;
    }
  
  }

    .icon{
        background:none;
        outline: none;
        border: none;
        display: flex;
        width: 36px;
        height: 36px;
        margin-right: .5rem;
  
        svg{
          width: 100%;
          height: 100%;
          padding: .5rem;
        }
        position: relative;
        color: var(--dark);
    
        .counter{
          width: 18px;
          height: 18px;
          border-radius: 100%;
          background-color: var(--red);
          position: absolute;
          transform: translate(12px, -8px);
          font-size: 10px;
          text-align: center;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      
      }

      .icon-md{
        svg{
            padding: .2rem;
        }
      }


      @media (max-width:786px) {
        .nav-list, .header_buttons{
            display: none;
          }
      }