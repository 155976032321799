
  .profile-header{
    padding: 1rem;
    padding-bottom: 0;
    border-radius: 8px;
    box-shadow: none;
  }

  .profile-background-image {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 40vh;
    position: relative;
  }

  .profile-picture-wrapper {
  transform: translateY(-70px);
  width: 165px;
  height: 165px;
  }
  
  .profile-picture-wrapper img {
    object-fit: cover;
    border-radius: 50%;
    margin-right: 30px;
    border: 5px solid white;
  }

    

  .profile-top-details {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    height: 163px;
    padding: 0 2rem;
  }
  .profile-name-wrapper {
    margin-top: 2rem;
    margin-left: 1rem;

  }
  .profile-name{
    font-size: 32px;
  }
  .profile-buttons {
    margin-top: 3rem;
    text-align: end;
    flex-grow: 2;

    &>:nth-child(2){
        margin-left: 1rem;
    }
  }


  .profile-edit-icon {
    display: flex;
    width: 13px;
    height: 13px;
    background:white;
    border-radius:50% ;
    padding: 15px;
    position: absolute;
    bottom: 32px;
    right:32px;
    align-items: center;
    justify-content: center;
  }
  .profile-edit-image-icon {
    display: flex;
    width: 13px;
    height: 13px;
    background:white;
    border-radius:50% ;
    padding: 15px;
    position: absolute;
    bottom: 15px;
    right: 20px;
    align-items: center;
    justify-content: center;
  }
  .profile-edit-image-icon svg {
    display: inherit;
    position:inherit ;
  }
  .profile-edit-icon svg {
    display: inherit;
    position:inherit ;
  }
  


  .profile-body-nav {
    display: flex;
    padding-left: 2rem;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #f1f1f1;
  }
  
  .profile-body-nav button {
    min-width: 100px;
    height: 45px;
    background: none;
    border: none;
    padding: 7px;
    font-weight: 500;
    border-bottom: 2px solid white;
  }

  .profile-body-nav .swiper-slide {
   width: fit-content;
  }
  
  .profile-body-nav button:hover {
    border-bottom: 2px solid var(--blue);
  }
  
  .profile-body-nav-active {
    color: var(--blue);
    border-bottom: 2px solid var(--blue) !important;
  }

  .tab_placeholder{
    min-height: 500px;
    display:flex;
    margin: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    h1{
        color: var(--BACKGROUND);
        font-size: 72px;
    }
  }
  
  .profile_main {
    width: 100%;
    padding-bottom: 20px;
  }

  
  .profile_top {
    display: inline-flex;
    margin: 0.5rem;
    background: #f8f8f8 !important;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
  }

  .my_shops {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #faf9f9;
    padding: 1rem 3rem;
    
  }
  
  .shop_name {
    font-size: 18px;
    font-weight: 600;
    color: #333;
  }
  
  .shop_address {
    font-size: 15px;
    color: #333;
  }
  
  .shop_promo {
    font-size: 13px;
    color: var(--ORANGE);
  }



  .shop_products {
    display: grid;
      flex-wrap: wrap;
      background-color: #f6f6f6;
      padding-top: 15px;
      /* align-items: flex-start; */
      min-height: 60vh;
      grid-template-columns: repeat(auto-fit, minmax(220px,230px));
      grid-gap: 3rem;
  }
  
  .shop_badge{
    color: #1B9CEA;
    margin: 0 0 -2px 2px;
    font-size: 15px;
  }

  .profile_information {
    display: block;
    width: inherit;
    height: inherit;
  }
  

  .shop_name {
    color: #333;
    font-size: 27px;
    font-weight: 700;
    cursor: pointer;
  }
  .shop_name:hover {
    color: #0160FE;
  }
  
  .create_shop {
    color: #fff;
    background-color: var(--ORANGE);
    padding: 18px 38px;
    border-radius: 15px;
    border: none;
    margin-left: 10px;
  }
  .create_shop_white {
    color: var(--ORANGE);
    background-color: white;
    padding: 17px 39px;
    border-radius: 15px;
    border: 1px solid var(--ORANGE);
    margin-left: 10px;
  }
  
  .profile_email {
    color: #333;
    font-size: 15px;
  }
  .shop_email {
    color: #333;
    font-size: 15px;
    cursor: pointer;
  }
  .shop_email:hover{
    color:#0160FE;
  }



@media (max-width:786px) {

.profile-header{
        padding: 0;
        border:none;
        box-shadow: none;
      }

  .profile-top-details {
    padding: 0 1rem;
    flex-direction: column;
    height: 100%;
    transform: translateY(-50px);
  }
  .profile-picture-wrapper{
    transform:none;
    position: relative;
    width: 135px;
    height: 135px;
  }


  .profile-name-wrapper {
    margin-top: 1rem;
    transform: translateY(-10px);
    }

    .profile-name{
        font-size: 28px;
    }

  .profile-buttons {
    margin-top: 1rem;
    flex-grow: unset;
    text-align: justify;
  }

  .profile_wrapper {
    min-height: calc(100vh - 80px);
  }

  .profile_module{
    width:100%;
    overflow: hidden;
    margin: 0;
    &>*{
      min-width: 100%;
    }
    
  }

  .profile-body-nav {
    padding: 0 1rem;
  }

  .my_shops {
    padding: 15px;
    
  }

  
}

@media (max-width:450px) {

.profile-background-image {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 30vh;
    position: relative;
    }
    .profile-name{
        font-size: 21px;
    }

    .profile-name-wrapper{
        p{
            font-size: 13px;
        }

    }

  .profile-picture-wrapper {
    width: 110px;
    height: 110px;

   img{
    width: 110px;
    height: 110px;
   }

}

// .profile-wrapper .btn-secondary, .profile-wrapper .btn-primary {
//   border: 1px solid var(--blue);
//   outline: none;
//   padding: 0 1rem;
//   height: 40px;
//   font-size: 15px;
//   border-radius: 15px;
//   transition: all 0.2s ease-in;
// }

  
}