.chat {
  background-color: white;
  .chat_container {
    display: flex;
    margin: auto;
    max-width: 1440px;

    .chat_list_wrapper {
      flex-basis: 30%;
      background-color: rgba(248, 248, 248, 0.4);
      overflow: hidden;
      padding: 0 2rem;
      height: calc(100vh - 80px);
      .chat_list_search {
        background-color: #f5f8ff;
        color: var(--placeholder-color);
        display: flex;
        align-items: center;
        border-radius: var(--border-radius);
        overflow: hidden;
        flex: 1;
        max-width: 100%;
        margin-top: 2rem;
        height: 40px;
        input {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 10px;
          font-size: 15px;
          border: none;
          outline: none;
          background: none;
          cursor: pointer;
          height: 100%;
        }
      }
      .chat_list {
        padding: 1rem 0;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 8px;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          transition: all 0.1s ease-out;
          border-radius: 10px;
          background-color: #c1c1c1;
        }

        .chat_list_item {
          display: flex;
          justify-content: space-between;
          padding: 1rem 0;
          border-bottom: 1px solid rgba(248, 248, 248, 0.5);

          h5 {
            font-size: 15px;
          }
          cursor: pointer;
        }
        .chat_list_item_active {
          border-radius: 10px;
          background-color: #f5f8ff7c;
          border: none;
        }
      }
    }
    .chat_placeholder {
      overflow: hidden;
      flex-basis: 70%;
      width: 100%;
      height: calc(100vh - 80px);
      // background-color: rgba(248, 248, 248, 0.4);

      .placeholder_image {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 400px;
          height: fit-content;
          opacity: 0.1;
        }
      }
    }
    .chat_body_wrapper {
      overflow: hidden;
      flex-basis: 70%;
      width: 100%;
      height: calc(100vh - 80px);

      .chat_header {
        width: 100%;
        background-color: white;
        display: flex;
        height: 55px;
        padding: 1rem;
        box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
        .topic_image {
          img {
            width: 130px;
            height: auto;
          }
        }
        .header_details {

          display: flex;
          width: 100%;
         justify-content: space-between;
          margin-left: 1rem;
          button{
            font-size: 24px;
            background:none;
            outline: none;
            border: none;

          }
          .name {
            font-weight: 600;
          }
        }
      }


      .chat_body {
        height: calc(100vh - 215px);
        overflow: auto;

        &::-webkit-scrollbar {
          width: 8px;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          transition: all 0.1s ease-out;
          border-radius: 10px;
          background-color: #c1c1c1;
        }



        %message {
          margin: 1rem 0;
          padding: 15px;
          line-height: 1.5;
          font-size: 14px;
          font-weight: 500;

          .date {
            color: #686868;
            font-size: 12px;
            margin-top: 0.5rem;
            text-align: end;
          }
        }

        .message_sent {
          width: 100%;
          display: flex;         
          justify-content: flex-end;

          .message {
            margin-right: 1rem;
            @extend %message;
            max-width: 60%;
            background-color: #f5f8ff;
        border-radius: 20px 20px 0 20px;

            .date {
              text-align: end;
            }
          }
        }
        .message_received {
          width: 100%;

          display: flex;
          justify-content: flex-start;

          .message {
            margin-left: 1rem;
            @extend %message;
            border-radius: 20px 20px 20px 0;    
            max-width: 70%;
            width: fit-content;
            background-color: rgba(245, 248, 255, 0.5);

            .date {
              text-align: start;
            }
          }
        }
      }
      .chat_form {
        height: 50px;
        width: 100%;
        padding: 0 2rem;
        @media (max-width:450px) {
          padding: 1rem 1rem 0;
        }
        padding-top: 1rem;
        margin-bottom: 1rem;
        border-top: 1px solid #e3e3e3;
        margin-top: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        label{
          font-size: 18px;
          margin-right: 1rem;
        }
        #attach{
          display: none;
        }

        .form_message{
          display: flex;
          justify-content: space-between;
          background-color: rgba(245, 248, 255, 1);
          padding: 0.8rem;
          height: 100%;
          height: 48px;
          border-radius: 10px;
          width: 100%;


          &>* {
            outline: none;
            border: none;
            background: none;

          }
          input{
            width: 100%;
          }
          button{
            font-size: 24px;
          }


        }
      }
    }
  }


  @media (max-width:786px) {

    

      .chat_container{
        width: 100%;
        max-height: calc(100vh - 80px);
        flex-direction: column;
        .chat_list_wrapper{
          flex-basis: 100%;
          width: 100vw;
          overflow-y: auto;
          margin: 0;
          padding: 0 2rem;

      
        }
      
        .chat_body_wrapper{
          width: 100vw;
          flex-basis: 100%;
          display: none;
        }
        .display_chat{
          display: block;
        }
        .hide_chat_list{
          display: none;
        }

        .chat_placeholder {
          display: none;

      }

      }

    
    
  
  
    
  }

  @media (max-width:450px) {

   
    

 
      .chat_list_wrapper{
        padding: 0 1rem !important;
        
    
      }

  
}
}


