

// BUTTONS

%btn{
  background: none;
  outline: none;
  border: none;
  border-radius: 5px;
  font-weight: 400;
  font-family: inherit;
  font-size: 14px;
  height: 36px;
  padding: 0 1rem;
}

.btn-primary{
  @extend %btn;
  border: 1px solid var(--blue);
  background-color: var(--blue);
  transition: all .3s ease-in;
  color: white;
}

.btn-secondary{
  @extend %btn;
  border: 1px solid var(--blue);
  color: var(--blue);

}


.form-group{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

.form-control{
  margin-bottom: 1rem;

  label{
    display: block;
    font-size: 15px;
    margin-bottom: .5rem;
    font-weight: 500;
  }

}



.form-input{
  width: 100%;
  display: block;
  outline: none;
  border: none;
  background-color: var(--background-color);
  border-radius: 5px;
  height: 40px;
  padding: 0.5rem 1rem;
  color: var(--dark);
}



 .toggler{
    position: relative;
    display: inline-block;
    width: 54px;
    height: 28px;
  }
  
  /* Hide default HTML checkbox */
   .toggler input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
   .toggler-slider
 {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:#ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
   .toggler-slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked +  .toggler-slider
 {
    background-color: #2196F3;
  }
  
  input:focus +  .toggler-slider
 {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked +  .toggler-slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
   .toggler-slider.round {
    border-radius: 34px;
  }
  
   .toggler-slider.round:before {
    border-radius: 50%;
  }


