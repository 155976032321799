// POST MODAL
.product {
    width: 100%;
    height: 100%;
    max-width: 235px;
    min-width: 235px;
    min-height: 250px;
    background-color: var(--white);
  }
  
  .product_image{
    width: 100%;
    max-width: 400px;
    min-height: 250px;
    position: relative;
  }
  .product_image_cover{
    display: block;
    width: 100%;
    height: 250px;
  }
  .product_top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    z-index: 1;
  }
  .product-type{
    position: absolute;
    padding: .3rem .6rem;
    right: 0;
    color: white;
    background-color: #19CC40;
    border-radius: 30px;
    margin: 1rem;
    font-size: 11px;
  }
  
  .product_details{
    height: 120px;
    padding: .8rem;
    position: relative;
    display: flex;
    font-size: 14px;
    justify-content: space-between;

  }
  .product_ends {
    font-size: 12px;
  }
  
  .product_like_show {
    color: var(--white);
    border: none;
    align-self: baseline;
    font-size: 24px;
    padding: .5rem;
    background: none;
    transition: all ease-in .3;
  }
  
  .product_like_hidden{
    display: none;
    transition: all ease-in .3;
  }
  
  
  
  .product_name {
    margin-top: .5rem;
    font-weight: 600;
    line-height: normal;
    font-size: 14px;
    color: var(--dark);
    display: none;
  }
  

  .product-price{
    display: flex;
    margin-top: 1rem;
    align-items: center;
    position: absolute;
    bottom: 1rem;

    p{
      font-weight: 500;
      font-size: 15px;
    }
    .price{
      color: var(--dark);
    }

  }

  .product_old {
    text-decoration: line-through;
    color:#818181;
  }

  .product_discount.price {
    color:var(--dark);
    margin-left: 1rem;
  }

  
  .product_bottom {
    display: flex;
    align-items: center;
    padding: 4px 0px;
  }
  
  
  .view_product_body {
    background-color: #f6f6f6;
    min-height: calc(100vh - 60px);
    padding-bottom: 30px;
  }
  .product_ad {
    font-size: 13px;
  }

  .options_show {
    border: none;
    padding: 5px 0px 5px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    font-size: 18px;
    color: #333;
    transition: all ease-in .3;
  }
  
  
  
  .options_hide{
      display: none;
      transition: all ease-in .3;
  }

  .product {

    .options{
      position: absolute;
      right: 1rem;
    }
  }
  