

.filter {
    opacity: 1;
    padding-top: 50px;
    width: 100%;
    height: calc(100vh - 80px);
    max-width: 260px; //Dont change this width
    font-size: 14px;
    font-weight: 300;
    
    @media (max-width: 786px){
      display: none;
    }
  
    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 10px;
    }
  
    &::-webkit-scrollbar-thumb {
      transition: all 0.1s ease-out;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #c1c1c1;
    }
  
    .filter_container {
      background-color: var(--white);
      & > * {
        padding: 1rem 0;
        max-width: 235px; 
      }
    }
  
  
  }
  .filter_modal{
    position: fixed;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    z-index: 999;
    top: 0;
    padding: 1rem;
    left: 0;
    overflow-y: scroll;
    background-color: var(--white);
  
    .filter_container {
      & > * {
        margin-bottom: 1rem;
       padding: 1rem 0;
        margin-top: 3rem;
      }
    }
  
  
  }
  
  
  
  .filter_header {
    display: flex;
    flex-direction: column;
    height: 53px;
    justify-content: center;
    font-weight: 400;
  }
  
  .filter_by_category {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .select {
      width: 100%;
      height: 100%;
      overflow: hidden;
      transition: all 0.3s ease-in;
  
      button {
        width: 100%;
        border: none;
        background: none;
        outline: none;
        color: var(--placeholder-color);
        font-size: 17px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        span {
          font-size: 18px;
        }
      }
  
      .options {
        list-style-type: none;
        padding: 0;
        transition: all 0.3ms ease-in;
        width: 200px;
  
        li {
          margin-top: 0.7rem;
          padding-left: 0.6rem;
          font-size: 15px;
          font-weight: 400;
          &::before {
            content: "";
            display: block;
            height: 100%;
            width: 3px;
            background-color: var(--blue);
          }
          input{
            margin-right: .5rem;
          }
          // &:hover {
          //   border-left: 3px solid var(--blue);
          // }
        }
      }
      .options_display {
        height: 100%;
      }
      .options_hide {
        height: 0;
      }
    }
  }
  
  .filter_by_price {
    display: flex;
    flex-direction: column;
  
    .price_range {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;
    }
  
    .price_range span {
      margin: 0 10px;
      color: #999;
      flex: 1;
    }
  
    .price_range input {
      width: 75px;
      height: 41px;
      outline: none;
      border: none;
      padding: 0.6rem;
      border-radius: var(--border-radius);
      color: var(--placeholder-color);
      background-color: var(--background-color);
    }
  
    .price_range input::-webkit-outer-spin-button,
    .price_range input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  
    .price_range button {
      border: none;
      background: none;
      font-size: 18px;
      outline: none;
      display: flex;
      justify-content: flex-end;
      flex: 1 1;
      margin-left: 5px;
      padding: 10px 0;
      cursor: pointer;
    }
  
    .range {
      display: flex;
      align-items: center;
      max-width: 170px;
      font-size: 13px;
      justify-content: space-between;
      margin-bottom: 0.5rem;
  
      input {
        width: 16px;
        height: 16px;
      }
    }
  }
  
  .filter_by_location {
    .select {
      width: 100%;
      height: 41px;
      padding: 0.6rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      outline: none;
      color: var(--text-color);
      margin-bottom: 1rem;
      border: none;
      background-color: #f1f1f1;
      border-radius: var(--border-radius);
  
      span {
        font-size: 18px;
      }
    }
  }
  
  .filter_header_select {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    outline: none;
    border: none;
    background: none;
    font-size: 16px;
    color: var(--text-color);
    font-weight: 600;
    margin-bottom: 1.5rem;
    transition: all 0.2s ease-in;
    padding: 0.6rem;
  
    &:hover {
      background-color: var(--background-color);
      color: var(--text-color);
      border-radius: 8px;
    }
  
    span {
      font-size: 18px;
    }
  }