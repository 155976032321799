
.auth{
    width: 100%;
    background-color: var(--white);
    height: 100%;
    min-height: 100vh;
    display: flex;

    .auth-image{
        flex-basis: 50%;
        height: 100vh;
        position: sticky;
        top: 0;
    }

    .auth-form{
        width: 100%;
        height: 100%;
        margin: auto;
        margin-top: 10%;
        display: flex;
        overflow-y: auto;
        margin-bottom: 3rem;

        .form_header{
            margin-bottom: 2rem;
            h2{
                margin-bottom: 1rem;
                font-size: 36px;
            }
            p{
                font-size: 15px;
            }
        }
        form{
            width: 100%;
            height: 100%;
            max-width: 370px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: auto;

            @media (max-width:786px) {
                padding: 0 1rem;
                
            }
            .google_auth{
                display: flex !important;
                justify-content: center;
                font-size: 15px;
                border-radius: 5px;
                background-color: var(--background-color);
                height: 40px;
                font-weight: 500;
                align-items: center;
                .auth_icon{
                    font-size: 1.5rem;
                    margin-right: 1rem;
                    display: flex;
                }
            }
            .form_hr{
                text-align: center;
                border-width: 0;
                border-top-width: 1px;
                border-color: var(--background-color);
                height: 0;
                overflow: visible;
                font-size: 14px;
                margin: 2rem 0;
        
                &::before{
                    content: 'or with email';
                    background-color: rgb(255, 255, 255);
                    width: 5rem;
                    display: inline-block;
                    width: 115px;
                    bottom: .7rem;
                    position: relative;
                }
            }
            
        
            .form_header{
                margin-bottom: 1.5rem;
                h1{
                    font-weight: 700;
                    margin-bottom: 0.5rem;
                    line-height: 2.25rem;
                    font-size: 28px;
                }
            }

            label[for="password"] .blue{
                font-weight: 400;
                font-size: 14px;
            }

            input.btn-primary{
                width: 100%;
                height: 40px;
            }
        
            .form-opt-wrap{
                display: flex;
                justify-content: space-between;
                margin-bottom: .5rem;
            }
            .agree{
                font-size: 13px;
                margin-bottom: 2rem;
            }
            .form-alt{
                position: absolute;
                font-size: 14px;
                top: 2rem;
                right: 2rem;
            }
    

            
        }

       
    }

    @media (max-width:786px) {

        .auth-image{
            display: none;
        }

        .form-alt{
            margin: 1rem 0;
            text-align: center;
            position: static !important;
        }
        
    }
}


// .form{
//     max-width: 500px;
//     padding: 3rem;
//     height: 100%;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     margin: auto;

//     .form-group
// {
//         display: flex;
//         justify-content: space-between;
//         &>*{
//             width: 48%;
//         }
//     }

//     .google_auth{
//         display: flex !important;
//         justify-content: center;
//         font-size: 15px;
//         align-items: center;
//         .icon{
//             font-size: 1.2rem;
//             margin-right: 1rem;
//         }
//     }
//     .form_hr{
//         text-align: center;
//         border-width: 0;
//         border-top-width: 1px;
//         border-color: #DCDCDC;
//         height: 0;
//         overflow: visible;
//         font-size: 16px;
//         margin: 2rem 0;

//         &::before{
//             content: 'or';
//             background-color: rgb(255, 255, 255);
//             width: 5rem;
//             display: inline-block;
//             bottom: 1rem;
//             position: relative;
//         }
//     }
    

//     .form_header{
//         margin-top: 2.5rem;
//         margin-bottom: 1.5rem;
//         h1{
//             font-weight: 700;
//             font-size: 1.875rem;
//             line-height: 2.25rem;
//         }
//     }

//     label{
//         font-weight: 500;
//         font-size: 13px;
//         input{
//             font-size: 14px;
//             color: var(--dark);
//         }
//     }
// }

