#search-page{
  background-color: var(--white);
}

.main_area {
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  max-width: 1200px;
}



// PRODUCTS SECTION

.products {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  padding-top: 50px;
  transition: all ease-in;

  .filter_btn {
    display: flex;
    align-items: center;
    max-width: 130px;
    margin-bottom:1rem ;
    padding: 1rem;
    font-size: 16px;
    background: none;
    border: 1px solid #70707033;
    z-index: 2;
  }
  @media (min-width: 786px){
    .filter_btn{
      display: none;

    }
  }

  @media (max-width: 786px){
    padding-left: 0;
  }

  .sort_panel {
    background-color:white;
    border: 1px solid #70707033;
    width: 100%;
    height: 61px;
    padding: 0 1rem;
    margin-bottom: 2rem;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .search_results {
      flex-grow: 2;
      & > {
        font-size: 18px;
      }
    }
    .view_menu {
      display: flex;
      max-width: 90px;
      justify-content: space-between;
      margin-left: 2rem;
      @media (max-width: 786px){
        display: none;
      }

      button {
        font-size: 24px;
        background: none;
        border: none;
        margin-left: 0.5rem;
        color: #dddddd;
        display: flex;
      }
      .active_grid svg{
        color: var(--dark);
     }
    }
    select {
      background-color: var(--background-color);
      width: 100%;
      height: 100%;
      max-width: 200px;
      max-height: 40px;
      border: 1px solid #70707033;
      border-radius: var(--border-radius);
      padding: 0.6rem;
    }

  }

  .details_panel {
    width: 100%;
    height: 40px;
    background-color: var(--background-color);
    display: none;
    margin-bottom: 30px;
    align-content: center;
    grid-template-columns: 3.5fr 1fr 1fr 1.2fr;

    .item_name {
      text-align: center;
    }
  }
  .details_panel_hide {
    display: none;
  }

  
}
.products_grid {
  flex: 1;
  width: 100%;
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(5, 1fr);

  @media (max-width:960px) {
    grid-template-columns: repeat(4, 1fr);
    
  }
  @media (max-width:786px) {
    grid-template-columns: repeat(2, 1fr);
    
  }
  @media (max-width:600px) {
    grid-template-columns: repeat(1, 1fr);
    
  }

  .product {
    max-width: 100%;
  }
}
.products_grid_4 {
  @extend .products_grid;
  grid-template-columns: repeat(4, 1fr);

  .product{
    min-width:100%;
  }

  .pagination-list{
    grid-column: 1/5
  }
}
.products_grid_3 {
  @extend .products_grid;
  grid-template-columns: repeat(3, 1fr);
  .pagination-list{
    grid-column: 1/4
  }
}
.products_grid_1 {
  @extend .products_grid;
  grid-template-columns: repeat(1, 1fr);

  .product{
    margin: 0;
  }

  @media (min-width:480px) {

    .product{
      display: flex;
      align-items: center;
      border: 1px solid;
      border: 1px solid #70707033;

    }
    .product_image{
      flex-basis: 40%;
    }
    .product_details{
      flex-basis: 70%;
      font-size: 18px;
  
    }
    
  }

  
}



.pagination-list{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 90px;
    align-items: center;

    li{
      width: 100%;
      height: 100%;
      max-height: 40px;
      max-width: 50px;
      a{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg{
          font-size: 32px;
        }
      }
      font-size: 21px;
    }
}

.previous-page,.next-page {
  background-color: #dddddd;
  border-radius: 5px;
} 

.active-page{
  background-color: aliceblue;
}
.disabled-page{
  display: none;
}