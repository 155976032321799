
.view_product_body {
  background-color: var(--background-color);
  min-height: calc(100vh - 80px);
  padding-bottom: 30px;
}

.path{
  height: 66px;
  display: flex;
  margin: 0 2rem;
  text-transform: uppercase;
  align-items: center;
}

.view_product_container {
  display: grid;
  grid-template-columns: 2.5fr 4fr 1.2fr;
  grid-column-gap: 1rem;
  padding: 0 2rem;
  align-items: flex-start;

}

.image_side {
  width:100%;
  height: 460px;
  top: 10px;
  position: sticky;
  background: none;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow-y: hidden;
}

.view_product_image {
  width: 100%;
  height: 350px;
  display: flex;
  border-radius: 5px;
  background-color: var(--white);
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.view_product_image img {
  max-width: 250px;
  height: auto;
}

.view_product_image button {
  z-index: 1;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  background: none;
  border: none;
  color: var(--black);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}


.image_slide>* {
  width: 100px;
  height: 80px;
  border-radius: 4px;
  object-fit: cover;
  margin-right: 10px;
  margin-top: 5px;
}

.view_product_name{
  h5{
      font-size: 16px;
      color: var(--black);
  }
}
.view_product_title{
  h3{
      font-weight: 400;
  }
}

.view_product_details {
  min-height: calc(100vh - 146px);
  &>*{
      background-color: var(--white);
      border-radius: 5px;
      padding: 20px;
  }
}

.view_product_mid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ribbon_side {
  display: flex;
  align-items: center;
}

.ribbon {
  background-color: var(--blue);
  padding: 9px 20px;
  border-radius: 4px;
  text-align: center;
  margin-right: 10px;
  color: white;
}

.ribbon p {
  line-height: 1.2;
  font-size: 15px;
  font-weight: 500;
}

.view_products_buttons{
  button{
      width: 100%;
      padding: 0.8rem 0;
      border: 2px solid var(--blue);
      border-radius: 5px;
      outline: none;
      background: none;
      font-size: 15px;
  }
  &>:nth-child(1){
      background-color: white;
      margin-bottom: 1rem;
      color: var(--text-color);
  }
  &>:nth-child(2){
      background-color: var(--blue);
      color: var(--white);
  }

}

.other_products_section{
  margin-top: 2rem;
  padding: 0;
  .other_products_nav{
    display: flex;
      width: 100%;
      height: 50px;

      button{
          width: 100%;
          max-width: 150px;
          height: 100%;
          font-size: 17px;
          background: none;
          border: none;
          outline: none;
      }

      .tab_active{
          color: var(--blue);
          border-bottom: 1px solid var(--blue);

      }
  }
  .other_products_body{
    min-height: 500px;
      .promotions{
          &>p{
              padding-left: 2rem;
              padding-bottom: 1rem;
              padding-top: 1rem;
              border-bottom: 1px solid #f1f1f1;
          }
          .other_products{
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid #f1f1f1;
              padding: 1rem 0.8rem;
              .other_product_image{
                  width: 130px;
              }
              .other_product_price{
                  display: flex;
                  align-items: center;
                  .price_discount{
                      padding: 1rem;
                      font-size: 17px;
                      background-color: var(--blue);
                      color: white;
                      border-radius: 5px;
                  }
                  .price_tag{
                      margin-left: 0.8rem;
                      display: flex;
                      flex-direction: column;
                      .prev_price{
                          text-decoration:line-through;
                      }
                      .price{
                          font-size: 16px;
                          font-weight: 600;
                          color: var(--black);
                      }
                  }

              }
          }
      }

      .location{
          .location_map{
              width: 80%;
              height: 300px;
              background-color: grey;
              margin: auto;
              padding: 1rem;
              padding-top: 5rem;
          }
      }
  }

}  

.side_promotions {
  position: sticky;
  top: 10px;
  background-color: var(--white);
  border-radius: 5px;
  width: 100%;
  max-height: 460px;
  padding: 10px 0;
  overflow-y: auto;


  &::-webkit-scrollbar {
      width: 8px;
      border-radius: 10px;
     }

     
     &::-webkit-scrollbar-thumb {
      transition: all .10s ease-out;
      border-radius: 10px;
     }
     &::-webkit-scrollbar-thumb:hover{
      background-color: #c1c1c1;
     }
}

.side_promotions>*:nth-child(1) {
  border-bottom: 1px solid #f9f9f9;
  text-align: center;
  padding-bottom: 5px;
  font-weight: 600;
  font-size: 17px;
  display: flex;
  min-height: 60px;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 5px 10px;
}
.side_promo_top_btn{
  background: none;
  color: var(--blue);
  border: none;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.side_promos {
  display: flex;
  flex-direction: column;
  padding: 15px 10px 8px 10px;
  font-size: 15px;
  color: var(--blue);
  cursor: pointer;
  min-height: 100px;

  &::after{
      content: '';
      display: block;
      width: 100%;
      margin: auto;
      height: 1px;
      background-color: #F2F2F2;
  }
}
.promos{
  display: flex;
  &>div {
      flex: 1;
      margin: 0px 10px;
    }
}


.side_promo_name {
  font-weight: 600;
  color: #333;
  line-height: 1.3;
}

.side_promo_promo {
  font-size: 12px;
}

.view_product_name {
  font-weight: 700;
  font-size: 18px;
}

.view_product_desc {
  font-size: 15px;
}
.view_product_cat {
  font-size: 13px;
  color: #999;
}

.view_product_store {
  margin-top: 10px;
  color: var(--blue);
  font-weight: 600;
  font-size: 15px;
}

.view_product_shop {
  margin-bottom: 20px;
  color: #333;
  font-weight: 500;
  font-size: 13px;
}

.view_product_ends {
  color: var(--blue);
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
}

.view_old_price {
  text-decoration: line-through;
  font-size: 14px;
}

.view_new_price {
  color: var(--ORANGE);
  font-size: 16px;
  font-weight: 600;
}

.view_product_about {
  min-height: 80px;
  font-weight: 400;
  margin-top: 15px;
}

.reserve {
  display: flex;
  flex-direction: column;
}

.reserve p {
  font-weight: 600;
  color: var(--ORANGE);
  font-size: 14px;
}

.reserve button {
  border: 1px solid var(--ORANGE);
  border-radius: 4px;
  padding: 10px;
  background: none;
  color: var(--ORANGE);
  font-weight: 500;
  margin-top: 5px;
  cursor: pointer;
}

.related_items {
  display: flex;
  flex-direction: column;
  
}

.related_items>h3 {
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 1.8rem;
  margin-top: 4rem;
}

.related_items_list {
  display: flex;
  overflow-x: auto;
  max-width: 100vw;
  grid-gap: 2rem;
  .Product{
      margin-left: 0;
  }
}

@media (max-width: 1200px) {
  .side_promotions{
    display: none;
  }
  .view_product_container {
    grid-template-columns: 3fr 4fr 0fr;
  
  }


}


@media (max-width: 786px) {

  .path{
    display: none;
  }
  .view_product_body{
    padding-top: 2rem;

  }
  .view_product_container {
    grid-template-columns: 1fr;
    padding: 0 1rem ;

  
  }
  .view_product_image{
    height: 100%;
    padding: 1rem;
  }
  .image_side {
    position: static;
    height: 100%;
  }
  .image_slide {
    justify-content: start;
    margin: 1rem 0;
  
  }


}

@media (max-width: 500px) {
  .view_product_container {
    padding: 0 ;

  
  }
 


}

