
.notifications{
    position: relative;
  }
  .notification{
    padding: .5rem 0;
    cursor: pointer;
    font-size: 12px;
  }
  .notifications_menu{
    display: none;
    cursor: default;
  //  border:  1px solid #999;
    background-color: var(--white);
    width: 100%;
    width: 300px;
    max-width: 300px;
    z-index: 999;
    right: 0;
    border-radius: 10px;
    min-height: 200px;
    max-height: 250px;
    text-align: start;
    position: absolute;
    box-shadow: 0px 15px 19px rgb(62 62 62 / 12%);
    transition: all ease-in .3s;
    .notifications_container{
      padding: 1rem;
  
    }
    .notifications_placeholder{
      margin: auto;
      width: 100%;
      height: 100%;
      padding: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
  
      span{
        font-size: 15px;
        font-weight:500 ;
        color: #B9B9B9;
      }
    }
  
  }
  .notifications:hover>.notifications_menu{
    display: block;
  }