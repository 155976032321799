.header_menu{
    display: none;
    position: fixed;
    z-index: 98;
    transform: translateX(-1000px);
    transition: all linear .3s;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    @media (max-width:786px) {
      display: block;
      
    }

    h4,h3{
      margin-bottom: 1rem;
    }
  }
  
  .menu-user{
    width: 100%;
    display: flex;
    margin-bottom: 2rem;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .icon-button{
    font-size: 42px;
    position: absolute;
    /* margin-bottom: 2rem; */
    left: 0;
    top: 1rem;
}

  .menu-link{
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg{
      font-size: 28px;

    }
    span{
      font-size: 15px;
      margin-left: 1rem;
    }
  }

    
  .header_menu_container{
    width: 100%;
    background-color: var(--white);
    z-index: 999;
    position: absolute;
    height: 100vh;
    right: 0;
    font-size: 16px;

    .close-modal{
      font-size: 42px;
      cursor: pointer;
    }

    .menu_navbar_nav{
        margin-top: 4rem;
        padding-left: 1rem;

    .nav_item{
      padding-bottom: 1rem;
      font-size: 18px;

      .item{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;

     
      }
    }

    }
    .menu-state{
      font-size: 18px;
      display: flex;
      position: absolute;
      margin: auto;
      bottom: 2rem;
      justify-content: space-between;
      &>:nth-child(1){
        font-weight: 500;
      }

    }

  }
  .menu_display{
    transform: translateX(0px);
    z-index: 999;
  

  }



.menu_navbar_nav{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    
}




.menu-primary-enter{
    position: absolute;
    transform: translateX(-110%);
  
  }
  .menu-primary-enter-active{
    transform: translateX(0%);
    transition: all var(--speed) ease;
  
  }
  
  .menu-primary-exit{
    position: absolute;
  
  }
  
  .menu-primary-exit-active{
    transform: translateX(-110%);
    transition: all var(--speed) ease;
  }
  
  
  .menu-secondary-enter {
    transform: translateX(110%);
  }
  .menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }

  .menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all var(--speed) ease;
  }
  