

.settings-tab-content{
    max-width: 600px;
    margin-bottom: 2rem;


    .content-header{
        margin-bottom: 1rem;
    }

    .content-wrapper {
        display: flex;
        justify-content: space-between;
    
        .text{
            max-width: 400px;
        }

    
    }
}

