.user-profile{
    margin-left: .5rem !important;
    position: relative;
  }

  .user-profile:hover>.profile-options{
    display: block;
  }
  .user-img{
    width: 36px;
    height: 36px;
    border-radius: 100%;
    overflow: hidden;
  }
  .user-profile:hover>.profile-options{
    display: block;
  
  }


 
  
  .profile-options{
    display: none;
    position: absolute;
    background-color: var(--white);
    right: 0;
    width: 215px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    height: fit-content;
    max-height: 270px;
    // transform: translateY(10px);
    font-size: 14px;
    z-index: 100;
    transition: all 0.3s ease-in;
  
    &>*{
      padding: .6rem;
      padding-left: 1rem;
      display: flex;
      align-items: center;
    }
  
    &>* span svg{
      font-size: 18px;
      margin-right:1rem;
      text-align: center;
      vertical-align: middle;
    }
  
    .user-details{
      display: flex;
      align-items: center;
      padding: 1rem;
      border-bottom: 1px solid var(--background-color);
      margin-bottom: 1rem;
      .user-icon{
        margin-left: 1rem;
      }
      .user-name{
        margin: 1rem;
        h4{
          font-size: 16px;
          line-height: 1.2;
        }
        h5{
          font-size: 12px;
          color: #B9B9B9;
          font-weight: 400;
        }
      }


    }

    .user-logout{
      padding-bottom: 1.5rem;
    }
  
    .nav-auth{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      span{
        margin-top: 1rem;
        display: block;
      }
    }


  }