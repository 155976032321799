.modal_btn{
    position: absolute;
    display: flex;
    align-items: center;
    bottom:2rem;
    right:2rem;
    margin: auto;
    width: 130px;
    z-index: 9999;
    border: none;
    justify-content: center;
    background-color: var(--dark);
    color: white;
    border-radius:8px;
    font-size: 16px;
    height: 45px;
    padding: 0 1rem;

    svg{
      margin-left:.5rem ;
    }
    // z-index: 99;
  
  }
  .check_radio {
    display: inherit;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .radio_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

  .close-modal{
    width: 32px;
    height: 32px;
    position: absolute;
    right: 1rem;
    svg{
    padding: .2rem;
    width: 100%;
    height: 100%;
    border-radius:100% ;
    background-color: #000000;
    color: white;
    }
  }

  .modal {
    position: absolute;
    overflow: hidden;
    width: 600px;
    height: 75vh;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--white);
    border-radius: 10px;

  }

  .overlay {
    position: fixed;
    z-index: 9;
    inset: 0px;
    background-color: #51515169;

  }

  .modal_top{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid lightgray;
    padding: 1.222rem 0;
  }

  .modal_container{
    width: 100%;
    height: 60vh;
    padding: 2rem;
    overflow: auto;

    // &::-webkit-scrollbar {
    //   width: 8px;
    //   border-radius: 10px;
    // }

    // &::-webkit-scrollbar-thumb {
    //   transition: all 0.1s ease-out;
    //   border-radius: 10px;
    //   background-color: #c1c1c1;
    // }
  }


  .nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
    // padding: 2rem 2rem 0 2rem;

  }

  .nav_item{
    font-size: 16px;
    width: 100%;
    cursor: pointer;
    padding: .8rem 0;
  }

  .nav_item_active{
    @extend .nav_item;
    background-color: #F5F8FF;
    border-bottom:2px solid var(--blue) ;
    color: var(--blue);
  }

  .main{
    margin-top: 2rem;
  }


  .form_control{
    margin-top:2rem;

    &>*{
      width: 100%;
      display: block;
      font-size: 14px;
      border: none;
      background-color: #F8F8F8;
      border-radius: 8px;
      outline: none;
    }

    label{
      font-size: 15px;
      background: none;
      border: none;
      margin-bottom: 0.5rem;
    }
    input, select{
      height: 45px;
      padding: 0 0.8rem;
    }
    textarea{
      min-height: 150px;
      padding: .8rem;
      font-family:inherit;
    }
  }

  @media (max-width:660px) {
    .modal {
      max-width: 100%;
      height: 100vh;

      border-radius: 0;
  
    }

    .modal_container{
      height: 90vh;

    
  }
}

  @media (max-width:450px){

    .modal_btn{

      bottom:1.5rem;
      right:1.5rem;
    
    }

    .modal {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-y: auto;
      width: 100%;
      border-radius: 0;
    }
    .nav{
      margin-top: 2rem;
    }

    .close-modal{
      font-size: 36px;
      position: absolute;
      right: 1rem;
      top: 1rem;
      transform: translate(0px, 0px);
      svg{
      padding: 0;
      border-radius:100% ;
      background:none;
      color: #000000;
      }
    }

  }