// HEADER SECTION

nav.site-nav{
    width: 100%;
    height: 100%;
    box-shadow: inset 0px -1px 0px #f3f3f4;
    background: white;
  
    .site-nav-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 1rem 0;
      flex-wrap: nowrap;
      position: relative;
  
    }

    .logo{
      margin-right: 3rem;
      align-self: flex-end;
    }

    .search-icon{
      margin: 0;
    }

    .post-modal{
      margin-left: 13rem;
    }
    .create-ad{
      border: none;
      font-size: 14px;
      font-weight: 500;
      color: var(--dark);
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
      margin-right: 2rem;
    }
    

  .mobile_icons{
    display: flex;
  
    &>:last-child{
      margin-right: 0;
    }
    .icon{
      display: none;

     }

    @media (max-width:786px) {

      .icon{
        display: flex;
  
       }
    }

  }
  
    @media (max-width:786px) {

      .logo{
        margin: 0;
      }

      .site-nav-wrapper{
        justify-content: space-between;
        flex-wrap: wrap;
      }
      .sub_nav{
        display: block
      }
      
    }
  
  }

  nav.tab-bar-nav{
    z-index: 8;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 70px;
    background-color: var(--white);
  }

  .bar-list{
    height: 80px;
    width: 100%;
    display: grid;
    // grid-template-columns: repeat(5,1fr);
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .middle-icon{
    background-color: var(--dark);
    color: white;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    width: 50px;
    height: 50px;
    transform: translateY(-5px);
    border-radius: 30px;
  }

  .user-icon{
    width: 40px;
    height: 40px;
    
    img{
      border-radius: 100%;
    }
  }

  .tab-bar-icon{
    display: flex;
    flex-direction: column;
    align-items: center;
    svg{
      font-size: 24px;
      border-radius: 5px;
    }

    span{
      margin-top: 0.3rem;
      font-size: 12px;
    }
  }
  
  
  
  
  .header_icons, .header_buttons{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 2;
  }
  

  .header_buttons{
  
    &>:first-child{
      margin-right: 1.2rem;
      border: none;
      outline: none;
      // font-size: 16px;
    }

    &>*{
      width: 90px;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
    }

    .btn-primary{
      background-color: var(--dark);
      color: white;
      border: 1px solid var(--dark);

    }
    .btn-secondary{
      border:  1px solid var(--dark);
      color: var(--dark);
      background: none;
    }

  }
  






