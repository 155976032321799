// .products_slider{

//     .product{
//         width: 100%;
//         padding: 1rem;
//         margin: 2rem;
//     }

//     .slick-arrow{
//         display: block;
//         background: #333;
//         border-radius: 100%;
    
//     }

// } 

.swiper-slide{
    .product{
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        margin: 0;
    }
}
