@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap');

*, *::after, *::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --BACKGROUND: #F8F8F8;
  --BORDER-SHADOW: 0.5px solid #B9B9B980;
  --white:#ffffff;
  --black:#000;
  --dark: #19191c;
  --blue: #3464c4;
  --red: #d3122a;
  --light-blue:#1B9CEA;
  --heading-color:rgb(48 47 47 / 92%);
  --text-color:rgb(90,90,90);
  --placeholder-color:rgb(104, 104, 104/92%);
  --background-color:#f8f8f8;
  --danger-warning:rgb(189, 69, 69);
  --speed: 500ms;
  --border-radius:10px;
  --body-font:'Roboto', san-sertif;
}


//Validations 
.hasError {
   border:1px solid var(--danger-warning) !important;
}
// SASS VARIABLES

%border_spacing{
  padding: 0 2rem;
}

// GLOBAL STYLES

.logo {
  max-width: 130px;
  height: auto;

  @media (max-width:786px) {
    max-width: 115px;

}
  }

// TYPOGRAPHY

body{
  font-family: var(--body-font);
  font-size: 15px;
  color: var(--text-color);
  line-height: 1.5;
  font-weight: 400;
  background-color: #faf9f9;
}
h1,h2,h3,h4{
  color: var(--heading-color);
  font-weight: 500;
  line-height: 1.2;
}

h1{
  font-size: 36px;
  @media (max-width:600px) {
    font-size: 28px;
    
  }

}

h2{
  font-size: 32px;
  @media (max-width:600px) {
    font-size: 24px;
    
  }

}

h3{
  font-size: 24px;

  @media (max-width:600px) {
    font-size: 21px;
    
  }

}

h4{
  font-size: 18px;

  @media (max-width:600px) {
    font-size: 16px;
    
  }


}

ul{
  list-style-type: none;
  padding: 0;
}
img{
  max-width: 100%;
}

.container{
  width: 100%;
  max-width: 1440px;
  padding: 0 2rem;
  margin: auto;

  
  @media (max-width:786px) {

    padding: 0 1rem;

}
}

.d-flex{
  display: flex;
  justify-content: space-between;
}

.lg{
  @media (max-width:786px) {
    display: none;
    
  }
}

.md{
  display: none;

  @media (max-width:786px) {
    display: block;
    
  }
}


.ptr {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: unset;
  cursor: pointer;
}

.blue{
  color: var(--blue);
}

button {
  cursor: pointer;
}

.image-container{
  width: 100%;
  height: 100%;
  position: relative;

  img{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      object-fit: cover;
      object-position: center;
      transition: all 0.2s linear;
  }
}

.md{
  display: none !important;
  @media (max-width:786px){
    display: block !important;
  }
}

.show {
  display: block;
}
.hide {
  display: none;
}





.main_color {
  color: var(--blue);
}

.close-modal{
  font-size: 32px;
  position: absolute;
  right: 1rem;
  top: 1rem;
}







// FOTTER SECTION


.Footer {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  border-top: 1px solid #f5f5f5;
}

.footer_about {
  flex: 1;
  width: unset;
}

.footer_desc {
  font-size: 14px;
  max-width: 600px;
}

.footer_header {
  font-weight: 600;
  font-size: 17px;
}

.footer_column {
  display: flex;
}

.footer_column>* {
  min-width: 100px;
  margin-left: 20px;
}


.delete_shop_btn{
  padding: 10px 30px;
  border-radius: 8px;
  margin-top: 20px;
  border: 1px solid var(--ORANGE);
  color: var(--ORANGE);
  background-color: white;
}


.action_container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.action_row{
  position: absolute;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  bottom: -5px;
  min-width: 140px;
  display: flex;
  flex-direction: column;
  right: 25px;
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
}
.action_rows_btn{
  text-align: justify;
  background: none;
  background-color: white;
  border: none;
  padding: 5px;
  font-size: 13px;
  border-bottom: 1px solid #f9f9f9;
}
.action_rows_btn:hover{
  background-color: var(--ORANGE);
  color: white;
  border-bottom: 1px solid var(--ORANGE);
}
.shop_row {
  display: flex;
  align-items: center;
  width: 49%;
  padding: 10px;
  flex-wrap: wrap;
  background-color: white;
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  cursor: pointer;
}

.store_image {
  width: 100px;
  height: 85px;
  border-radius: 4px;
  object-fit: cover;
  margin-right: 10px;
}

.img_upload_btn{
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #f1f1f1;
  width: 120px;
  height: 110px;
  justify-content: center;
  font-size: 25px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin: 0 5px 10px 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  color: #999;
}
.img_upload_btn:hover{
  color: var(--ORANGE);
}
.img_upload_btn span{
  font-size: 13px;
  margin-top: 3px;
}
.upload_image-containers{
  border: 1px solid #f1f1f1;
  width: 120px;
  height: 110px;
  margin: 0 5px 10px 0;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
.upload_image-containers > img{
  width: 100%;
  height: 80px;
  object-fit: cover;
}
.uploaded_images_bot{
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #f9f9f9;
}
.uploaded_images_bot > button{
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  padding: 4px 5px;
  font-size: 17px;
}
.delete_image{
  color: var(--ORANGE);
}


.Login_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--ORANGE);
  color: white;
  font-size: 16px;
  border: none;
  margin-left: 10px;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
}



.link_back{
  font-size: 12px;
  color: #0160FE;
  cursor: pointer;
  margin-top: 4px;
}
.no_active_promos{
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.no_active_icons{
  font-size: 70px;
  color: #bdbaba;
}
.no_active_text{
  font-size: 14px;
  color: #bdbaba;
  margin-top: 15px;
}

@keyframes loading {
  from {transform: rotate(0deg)}
  to {transform: rotate(360deg)}
}





// Footer Componet






// LOADER

@keyframes loading {
  from {transform: rotate(0deg)}
  to {transform: rotate(360deg)}
}


.loading_screen_off{
  display: none;
}
.loading_screen{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: var(--white);
  // padding-bottom: 10vw;
  margin: auto;
}
.loader_icon{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 72px;
  color: var(--black);
  animation: loading ease-in-out .8s infinite;
  }


.cart{
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 80px);
  margin: auto;
  align-items: center;

}

.error_page{
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 80px);
  margin: auto;
  align-items: center;
  h1{
    padding: 0 2rem;
  }
}





// RESET SLIDER PROPERTIES



.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  display: none !important;
}

.swiper-button-next, .swiper-button-prev{
  color: #333 !important;
}

.category_swiper{

  .swiper-button-next, .swiper-button-prev{
      
      width: 50px;
      background-color: white; 
      background-blend-mode: overlay;
      backdrop-filter: drop-shadow(2px 4px 10px white);

  }

  .swiper-button-next{
    right: 0;
  }
  .swiper-button-prev{
    left: 0;
  }

}