// footer{

//     border-top: 1px solid #f3f3f4;
//     .footer_wrapper{
//       display: flex;
//       flex-wrap: wrap;
//       padding-bottom: 70px;
//       justify-content: space-between;
  
//       &>*{
//         padding-top: 50px;
//       }
  
//       @media (max-width:500px) {
//         flex-direction: column;
//         .brand_des{
//           max-width: 100%;
//         }
        
//       }
  
  
//       .footer_brand{
//         flex-grow: 2;
  
//         .brand_des{
//           max-width: 350px;
//           margin-top: 1rem;
//         }
//       }
  
//       .footer_col{
//         flex-grow: 0.5;
//         max-width: 255px;
//         display: flex;
//         flex-direction: column;
//         align-items: justify;
  
//         h4{
//           margin-bottom: 0.2rem;
  
//           font-size: 18px;
//         }
  
//         ul{
//           list-style-type: none;
//           padding: 0;
//           li{
//             margin-bottom: 0.3rem;
//           }
  
//         }
//       }
//     }
//   }


footer{
  width: 100%;
  min-height: 200px;
  height: 100%;
  padding-top: 40px;
  background-color: var(--black);
  border-top: 1px solid #f3f3f4;
}

.footer-links .footer-list{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  grid-gap: 1rem;
  .footer-list-item{
    margin: 0 .8rem ;
    margin-top: 1rem;
    font-size: 15px;
    color: var(--white);
  }
}

.footer-copy{
  text-align: center;
  padding-top: 60px;
}

