

.shop_page{
    background: #f8f8f8 0% 0% no-repeat padding-box;
    padding-bottom: 3rem;
}

.shop_header{
    background-color: white;
}
.shop_header_container, .shop_body{
    max-width: 960px;
    padding: 0 1rem;
    margin: auto;
}

