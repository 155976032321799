
// PROFILE PAGE

.profile {
    min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .profile-container{
    max-width: 1440px;
    padding: 0 2rem;
    margin:auto ;
  }

  .profile-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin: auto;
    // justify-content: space-between;
  
  }

  .profile_navigation {
    padding: 1rem;
    background-color: var(--white);
    border-radius: 8px;
    width: 300px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    .profile_navbar{
      list-style-type: none;
      padding: 0;

      .profile_nav_item {
        cursor: pointer;
        font-size: 16px;
        height: 50px;
        opacity: 1;
        color: gray;
      }
      .active {
        color: black;
      }

    }

    @media (max-width:786px) {
      display: none;
      
    }

   
  }
  

  .profile_module{
    width: 70%;
    height: 100%;
    margin-left: 2rem;
    // background-color: var(--white);
    &>*{
        min-width: 795px;
        width: 100%;
        background:#ffff;
        border-radius: 8px;
        // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }

    // .form_input{
    //   margin-bottom: 1rem;
    //     label{
    //         font-size: 15px;
    //         font-weight: 500;
    //         margin-bottom: 0.2rem;
    //         display: block;
    //     }
    //     input{
    //         border:1px solid #DCDCDC;
    //         border-radius: 5px;
    //         outline: none;
    //         width: 100%;
    //         height: 45px;
    //         padding: 0 0.8rem;

    //     }
    // }

    .module_form_buttons{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 3rem;

        
    }



  }



  .content_container{
    padding: 3rem;
    margin-bottom: 3rem;
    background-color: var(--white);

    @media (max-width:786px) {
      padding: 1rem;
      
    }
    .content_heading{
        margin-bottom: 2rem;
        h2{
          font-size: 24px;
          font-weight: 400;
        }
    }
    
  }

  .profile_menu{
    background:none;

    nav{
      width: 100%;
      height: 100%;
      ._menu{
        display: flex;
        list-style-type: none;
        padding: 0;
        flex-direction: column;
        .profile_nav_item{
          border-bottom: 1p solid grey;
          padding: 1rem;
          font-size: 18px;
        }
      }
    }
  }

  .account{
    background: none;
    .details_form{
      width: 100%;
      display: flex;
      flex-direction: column;
  
      .details_form_grid{
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: repeat(3, 1fr);
          grid-column-gap: 2rem;
          grid-row-gap: 1rem;
  
      }
  }

  }

  .profile_security{
    .security_heading{
        margin-bottom: 3rem;
    }
    .security_form_wrap{
        display: flex;
        align-items: flex-start;
        // justify-content: space-between;
        .security_form{
            width:45%;

            .form_input{
                margin-bottom: 1rem;
            }

            
        }
        .form_errors{
            margin-left: 3rem;
            span{
                color: red;
                text-decoration: line-through;
                display: block;
            }
        }
    }

  }




@media (max-width:786px) {

  .profile_module{
    &>*{
      border:none;
  }
  }
  .profile-container{
    padding: 0;
  }
  
  .profile-wrapper{
    padding:0;
  }
}