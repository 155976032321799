// .slick-slide{
//     width: fit-content !important;
// }

section{
    padding: 50px 0;

}




.navbar{
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    background-color: var(--white);
    .nav_list{
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
  
        .nav_list_item{
            padding: 0.8rem;
            font-size: 14px;
            transition: all .2s ease-in;
            cursor: pointer;
            &:hover{
                background-color:#4fcaeb0a;
            }
            span{
                font-size: 20px;
                line-height: 1;
                vertical-align:sub;
            }
  
        }
    }
  
    @media (max-width:786px) {
      display: none;
      
    }
  }
  
.section_heading{
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
    justify-content: space-between;
    h3{
        font-weight: 600;
    }

    .see-all-tag{
        display: flex;
        align-items: center;
        font-size: 21px;
        margin-left: 3rem;
    
        svg{
            margin-left: 1rem;
        }

        @media (max-width:450px) {

            margin-left: 0;
           
            .text{
                display: none;
            }
            
        }
    
    }

}
.home_category{
    @media (max-width:786px){
        display: none;
    }
}

.hero_slider{
    height: calc(100vh - 140px);
    max-height: 460px;
    max-width: 1440px;
}

.category_circles{
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width:786px) {
        align-items: baseline;
        
    }
    @media (max-width:450px) {
        justify-content: space-between;
        
    }

}

.hero{
    width: 100%;
    max-height: 60vh;
    max-width: 1440px;
    margin: auto;
    overflow: hidden;

    .hero_content{
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 999;
        height: 70vh;
        max-height: 460px;
        justify-content: center;
        h1{
            max-width: 400px;
            color: #000000de;
        }
        button{
            max-width: 200px;
            margin-top: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 .8rem;
            height: 45px;
            border-color: #000000;
            color: rgba(0, 0, 0, 0.8705882353);
            font-size: 17px;
            font-weight: 500;
            transition: all 0.2s ease-in;

            svg{
                font-size: 24px;
                margin-left: .5rem;
            }

            &:hover{
                background-color: #000000;
                color: white;
            }

        }
    }

    @media (max-width:786px) {

        max-height: 50vh;

        .hero_content{
            max-height: 50vh;
        }
        
    }

    @media (max-width:600px) {

        max-height:40vh;

        .hero_content{
            max-height: 40vh;
        }
        
    }

}

.banner{
    padding: 0;
    width: 100%;
    height: 350px;
    max-width: 1440px;
    margin: auto;
    background-image:url('../../src/assets/banner.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 5rem;

    .banner_content{
        display: flex;
        flex-direction: column;
        height: 350px;
        justify-content: center;
        h2{
            max-width: 500px;
            color: white;
        }
        button{
            display: flex;
            align-items: center;
            max-width: 200px;
            margin-top: 2rem;
            padding: 0 1rem;
            height: 50px;
            border-color: white;
            color: white;
            font-size: 16px;
            font-weight: 500;
            transition: all .2s ease-in ;
            svg{
                font-size: 24px;
                margin-left: .5rem;
            }

            &:hover{
                background-color: white;
                color:#428fa4;
            }

        }

    }
}

